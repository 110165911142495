import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

const styles = {
  h1: css`
    font-size: 3.5rem !important;
    font-weight: bold !important;
  `,
  h2: css`
    font-size: 2rem !important;
    font-weight: bold !important;
  `,
  h3: css`
    font-size: 1.7rem !important;
    font-weight: bold !important;
  `,
  h4: css`
    font-size: 1.1rem !important;
    font-weight: 600 !important;
  `,
  h5: css`
    font-size: 1rem !important;
    font-weight: 500 !important;
  `,
  h6: css`
    font-size: 0.8rem !important;
    font-weight: 800 !important;
  `,
};

const Title = styled.h1.attrs(({ variant }) => ({
  as: variant,
}))`
  ${({ variant }) => styles[variant]}
`;

Title.displayName = 'Title';

Title.propTypes = {
  variant: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6']),
};

Title.defaultProps = {
  variant: 'h1',
};

export default Title;
