import React, { useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { Controller } from "react-hook-form";
import { TextField as MUITextField, InputAdornment } from "@material-ui/core";
import Icon from "./Icon";

const ComponentStyled = styled(MUITextField)`
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    border: none;
    -webkit-text-fill-color: #fff;
    box-shadow: 0 0 0 1000px transparent inset;
    transition: background-color 5000s ease-in-out 0s;
    caret-color: #fff;
  }
  label,
  .MuiInputBase-root {
    box-sizing: border-box;
  }
  label {
    transform: translate(0, 28px) scale(1) !important;
    padding-left: 15px;
    padding-right: 15px;
    &.MuiInputLabel-shrink {
      transform: translate(0, 1.5px) scale(0.75) !important;
    }
  }
  input,
  textarea,
  label,
  .MuiFormHelperText-root,
  .MuiInputAdornment-root {
    color: #fff !important;
    background: transparent;
    border: none;
  }

  .MuiInputBase-input {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
  }

  input.Mui-disabled,
  textarea.Mui-disabled,
  label.Mui-disabled,
  .MuiInputAdornment-root.Mui-disabled {
    color: #000 !important;
    opacity: 0.4;
  }

  &:focus-within {
    label {
      color: #fff;
    }
  }
  .MuiInput-underline {
    &::before {
      border-color: #fff !important;
    }
    &:hover:not(.Mui-disabled) {
      &::before {
        border-color: #fff;
        border-width: 1px;
      }
    }
    &.Mui-disabled {
      &::before {
        border-color: #000 !important;
        border-bottom-style: solid;
        opacity: 0.3;
      }
    }
    &::after {
      border-color: green;
    }
    &.Mui-error {
      &::after {
        border-color: #fff !important;
      }
    }
  }
  .MuiFormHelperText-root.Mui-error {
    align-self: flex-start;
    padding: 1px 6px;
    background: red;
    color: #fff;
  }

  .MuiIconButton-root {
    color: #fff;
    font-size: 1rem;
  }

  button {
    outline: none;
  }
`;

export const TextField = ({
  valid,
  error,
  caption,
  type,
  onChange,
  value,
  inputRef,
  formControl,
  rules,
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const [showPasswordValue, setShowPasswordValue] = useState(false);
  const [inputType, setInputType] = useState(null);

  function handleIcon() {
    const showIconValidation =
      !focused && (valid || error) && type !== "password";
    const showIconPassword = type === "password";

    function toggleShowPassword() {
      setShowPasswordValue(!showPasswordValue);

      if (showPasswordValue) {
        setInputType("password");
      } else {
        setInputType("text");
      }
    }

    return (
      <InputAdornment position="end">
        {showIconValidation && (
          <Icon icon={error ? ["fa", "times"] : ["fa", "check"]} />
        )}
        {showIconPassword && (
          <Icon
            style={{
              cursor: "pointer",
            }}
            onClick={() => toggleShowPassword()}
            icon={showPasswordValue ? ["far", "eye-slash"] : ["far", "eye"]}
          />
        )}
      </InputAdornment>
    );
  }

  const InputProps = {
    error: focused ? null : error,
    helperText: error && focused ? null : caption,
    type: inputType || type,
    onChange,
    value,
    InputProps: {
      endAdornment: handleIcon(),
      onFocus: () => {
        setFocused(true);
      },
      onBlur: () => {
        setFocused(false);
      },
    },
    ...props,
  };

  return formControl && !onChange ? (
    <Controller
      {...InputProps}
      as={ComponentStyled}
      control={formControl.control}
      rules={rules}
      value={value}
      onChange={onChange}
      defaultValue={value || ""}
    />
  ) : (
    <ComponentStyled
      {...InputProps}
      value={value}
      onChange={onChange}
      inputRef={
        inputRef || (formControl && onChange && formControl.register(rules))
      }
    />
  );
};

TextField.propTypes = {
  error: PropTypes.bool,
  valid: PropTypes.bool,
  caption: PropTypes.string,
  type: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  inputRef: PropTypes.func,
  rules: PropTypes.object,
  formControl: PropTypes.object,
};

export default TextField;
