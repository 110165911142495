import styled from "styled-components";

export const ContainerButton = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
`;

export const InputContainer = styled.div`
  margin: 14px 0;
`;
