function If(props) {
  if (props.test) {
    return props.children;
  }
  return false;
}

If.displayName = 'If';

export default If;
