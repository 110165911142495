/* eslint-disable eqeqeq */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import FontAwesomeIcon from './FontAwesomeIcon';

const ContainerLabel = styled.div.attrs({
  className: 'select-label-container',
})`
  color: ${({ theme }) => theme.colors.gray400};
  display: flex;
  align-items: center;
  justify-content: space-between;
  transition: all 0.2s;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  cursor: pointer;
`;

const Container = styled.div`
  position: relative;
  display: block;
  width: 100%;
  .select-label-container {
    border-color: ${({ theme }) => theme.colors.gray100};
    ${({ invalid, theme }) => invalid
      && css`
        border-color: ${theme.colors.danger};
      `}
    ${({ valid, theme }) => valid
      && css`
        border-color: ${theme.colors.primary};
      `}
    ${({ theme, disabled }) => disabled
      && css`
        color: ${theme.colors.gray100};
        cursor: not-allowed !important;
        button {
          color: ${theme.colors.gray100};
          cursor: not-allowed !important;
        }
      `}
  }
`;

const Label = styled.button.attrs({
  type: 'button',
  'aria-haspopup': 'listbox',
})`
  color: ${({ theme }) => theme.colors.gray400};
  background: transparent;
  outline: 0;
  border: 0;
  font-weight: 500;
  border-radius: 0;
  padding: 0.5rem;
  text-align: left;
  min-height: 42px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  i {
    margin: 0 10px;
  }
  &:focus {
    outline: 0;
  }
`;

const OptionsContainer = styled.ul.attrs({
  role: 'listbox',
  'data-testid': 'container-options',
})`
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0;
  list-style: none;
  display: block;
  width: 100%;
  max-height: 200px;
  overflow: auto;
  border: 2px solid ${({ theme }) => theme.colors.gray100};
  border-radius: 0 0 6px 6px;
  z-index: 1;
  background: ${({ theme }) => theme.colors.white};
  border-top: 0;
  li:last-child {
    border-bottom: 0;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
`;

const ButtonReset = styled.button.attrs({
  type: 'button',
  title: 'Limpar seleção',
  'data-testid': 'btn-reset-select',
})`
  color: ${({ theme }) => theme.colors.gray300};
  font-size: 0.8em;
  margin-right: 5px;
  background: transparent;
  outline: 0;
  border: 0;
  border-radius: 0;
  &:focus {
    outline: 0;
  }
`;

export const SelectContext = React.createContext({
  onSelect: null,
  setLabel: null,
  value: null,
});

function Select({
  disabled,
  placeholder,
  children,
  onChange,
  id,
  value,
  disableResetValue,
  ...rest
}) {
  const selectRef = useRef();
  const [selectedLabel, setSelectedLabel] = useState('');
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    let options = [];
    if (Array.isArray(children)) {
      children.forEach((item) => {
        if (Array.isArray(item)) {
          options = [...options, ...item];
        } else {
          options = [...options, item];
        }
      });
    } else if (children) {
      options = [children];
    }

    const [option] = options.filter(({ props }) => props.value == value);
    const label = option && !option.props.disabled ? option.props.children : '';
    setSelectedLabel(label);
  }, [value]);

  function toggleOpen() {
    if (disabled) return;
    setOpened(!opened);
  }

  function onSelect(item) {
    toggleOpen();
    onChange(item);
  }

  function handleReset() {
    if (disabled) return;
    onChange({});
    setOpened(false);
  }

  function handleClickOutside(event) {
    if (selectRef.current && !selectRef.current.contains(event.target)) {
      setOpened(false);
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);

    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);

  const customContext = {
    setLabel: setSelectedLabel,
    onSelect,
    value,
  };

  return (
    <SelectContext.Provider value={customContext}>
      <SelectContext.Consumer>
        {() => (
          <Container ref={selectRef} disabled={disabled} {...rest}>
            <ContainerLabel>
              <Label aria-expanded={opened} onClick={toggleOpen} id={id}>
                <span>{selectedLabel || placeholder}</span>
              </Label>
              <ButtonContainer>
                {!disableResetValue && selectedLabel && (
                  <ButtonReset onClick={handleReset}>
                    <FontAwesomeIcon icon="times" />
                  </ButtonReset>
                )}
                <FontAwesomeIcon
                  icon={opened ? 'chevron-up' : 'chevron-down'}
                  onClick={toggleOpen}
                />
              </ButtonContainer>
            </ContainerLabel>
            {opened && <OptionsContainer>{children}</OptionsContainer>}
          </Container>
        )}
      </SelectContext.Consumer>
    </SelectContext.Provider>
  );
}

Select.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.any,
  ]),
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  id: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disableResetValue: PropTypes.bool,
};

Select.defaultProps = {
  children: null,
  placeholder: 'Selecione uma opção',
  disabled: false,
  id: null,
  value: null,
  disableResetValue: false,
};

export default Select;
