export const errorMsgs = {
  email: {
    required: "O campo e-mail é obrigatório",
    validate: "E-mail inválido. Por favor, digite o seu e-mail corretamente",
    pattern: "E-mail inválido. Por favor, digite o seu e-mail corretamente",
  },
  password: {
    required: "O campo senha é obrigatório",
    validate: "Senha inválida",
    validateOnRegister: "Formato inválido",
    minLength: "Mínimo de 8 caracteres, contendo letras e/ou números",
  },
};

export default function getErrorMsg(errors, field) {
  return errorMsgs[field]?.[errors[field]?.type];
}
