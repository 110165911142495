import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import LoaderDefault from '../elements/LoaderDefault';
import If from '../helpers/If';

const placeHolder = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGQAAAAoCAQAAACicZfIAAAAOUlEQVR42u3PQREAAAwCoNm/9FL48aABuRERERERERERERERERERERERERERERERERERERERERHpe6NaACmJP5WhAAAAAElFTkSuQmCC';

const Image = styled.img`
  display: block;
  height: auto;
  width: auto;

  @keyframes loaded {
    0% {
      opacity: 0.1;
    }
    100% {
      opacity: 1;
    }
  }

  &:not(.fully-loaded) {
    visibility: hidden;
    width: 1px;
    height: 1px;
  }

  &.loaded:not(.has-error) {
    animation: loaded 300ms ease-in-out;
  }

  &.has-error {
    content: url(${placeHolder});
  }
`;

function LazyImage({ src, alt }) {
  const [imageSrc, setImageSrc] = useState(placeHolder);
  const [imageRef, setImageRef] = useState();
  const [loading, setLoading] = useState(true);

  const onLoad = (event) => {
    if (src === imageSrc) {
      event.target.classList.add('fully-loaded');
    }
    event.target.classList.add('loaded');
  };

  const onError = (event) => {
    event.target.classList.add('has-error');
  };

  useEffect(() => {
    let observer = null;
    let didCancel = false;

    if (imageSrc && imageSrc !== src) {
      if (imageRef) {
        imageRef.classList.remove('loaded', 'fully-loaded');
        setLoading(true);
      }
    }

    if (imageRef && imageSrc !== src) {
      if (window.IntersectionObserver) {
        observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (
                !didCancel
                && (entry.intersectionRatio > 0 || entry.isIntersecting)
              ) {
                setImageSrc(src);
                observer.unobserve(imageRef);
                setLoading(false);
              }
            });
          },
          {
            threshold: 0.01,
            rootMargin: '75%',
          },
        );

        observer.observe(imageRef);
      } else {
        setImageSrc(src);
        setLoading(false);
      }
    }

    return () => {
      didCancel = true;

      if (observer && observer.unobserve) {
        observer.unobserve(imageRef);
      }
    };
  }, [src, imageSrc, imageRef]);

  return (
    <>
      <If test={loading}>
        <LoaderDefault size="sm" />
      </If>
      <Image
        ref={setImageRef}
        src={imageSrc}
        alt={alt}
        onLoad={onLoad}
        onError={onError}
      />
    </>
  );
}

LazyImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string.isRequired,
};

export default LazyImage;
