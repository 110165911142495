import React from 'react';
import styled from 'styled-components';

interface ISpinnerProps {
  size: number;
}

const Container = styled.div<ISpinnerProps>`
  @keyframes spin {
    0% {
      transform: translate(-50%, -50%) rotate(0deg);
    }
    100% {
      transform: translate(-50%, -50%) rotate(360deg);
    }
  }
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  display: inline-block;
  overflow: hidden;
  background: none;
`;

const SpinnerContainer = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
`;

const SpinnerElement = styled.div<ISpinnerProps>`
  position: absolute;
  width: ${({ size }) => (size / 100) * 31}px;
  height: ${({ size }) => (size / 100) * 31}px;
  top: ${({ size }) => size * 0.5}px;
  left: ${({ size }) => size * 0.5}px;
  border: ${({ size }) => (size / 100) * 3}px solid
    ${({ theme }) => 'yellow'};
  border-top-color: transparent;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  box-sizing: content-box;
`;

export default function Spinner({ size = 200 }: ISpinnerProps): JSX.Element {
  return (
    <Container size={size}>
      <SpinnerContainer>
        <SpinnerElement size={size} />
      </SpinnerContainer>
    </Container>
  );
}
