import styled from 'styled-components';

import Input from './Input';

const Textarea = styled(Input).attrs({
  as: 'textarea',
})`
  min-height: 50px;
`;

Textarea.displayName = 'Textarea';

export default Textarea;
