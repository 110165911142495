import styled from 'styled-components';

const Link = styled.a`
  text-decoration: none;
  font-size: 11px;
  color: inherit;
  cursor: pointer;
  &:active,
  &:hover {
    text-decoration: underline;
    color: ${props => props.theme.colors.primary};
  }
`;

export default Link;
