import React from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';

const variants = {
  h1: 52,
  h2: 42,
  h3: 32,
};

function makeBorder({
  border,
  borderTop,
  borderRight,
  borderBottom,
  borderLeft,
  theme,
}) {
  return css`
    ${(border || borderTop)
      && css`
        border-top: 0.25em solid ${theme.colors.secondary};
        padding-top: 5px;
      `}
    ${(border || borderRight)
      && css`
        border-right: 0.25em solid ${theme.colors.secondary};
        padding-right: 20px;
      `}
    ${(border || borderBottom)
      && css`
        border-bottom: 0.25em solid ${theme.colors.secondary};
        padding-bottom: 5px;
      `}
    ${(border || borderLeft)
      && css`
        border-left: 0.25em solid ${theme.colors.secondary};
        padding-left: 20px;
      `}
  `;
}

const TitleContainer = styled.div`
  font-size: ${({ variant }) => `${variants[variant]}px`};
  font-weight: bold;
  display: flex;
  h1,
  h2,
  h3 {
    font-weight: bold !important;
    margin: 0;
  }
  flex-direction: ${({ subtitlePosition }) => (subtitlePosition === 'top' ? 'column-reverse' : 'column')};
  ${props => makeBorder(props)}
`;

const TitleElement = styled.h1.attrs(({ variant }) => ({
  as: variant,
}))``;

const Subtitle = styled.span`
  font-size: 0.35em;
`;

function FeaturedTitle({
  variant, subtitle, children, ...props
}) {
  return (
    <TitleContainer variant={variant} {...props}>
      <TitleElement variant={variant}>{children}</TitleElement>
      {subtitle && <Subtitle>{subtitle}</Subtitle>}
    </TitleContainer>
  );
}

FeaturedTitle.propTypes = {
  variant: PropTypes.oneOf(['h1', 'h2', 'h3']),
  border: PropTypes.bool,
  borderTop: PropTypes.bool,
  borderRight: PropTypes.bool,
  borderBottom: PropTypes.bool,
  borderLeft: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.any]),
  subtitle: PropTypes.string,
  subtitlePosition: PropTypes.oneOf(['top', 'bottom']),
};

FeaturedTitle.defaultProps = {
  variant: 'h1',
  border: false,
  borderTop: false,
  borderRight: false,
  borderBottom: false,
  borderLeft: false,
  children: '',
  subtitle: '',
  subtitlePosition: 'bottom',
};

FeaturedTitle.displayName = 'FeaturedTitle';

export default FeaturedTitle;
