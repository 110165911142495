import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

function getColor({
  theme, checked, disabled, indeterminate,
}) {
  let color = theme.colors.gray400;
  if (disabled) {
    color = theme.colors.gray100;
  } else if (checked || indeterminate) {
    color = theme.colors.primary;
  }
  return color;
}

const verticalAlignOptions = {
  center: 'center',
  top: 'flex-start',
  bottom: 'flex-end',
};

const Radio = styled.span`
  width: 22px;
  height: 22px;
  display: flex;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  position: relative;
  transition: all 0.2s ease;
  border: 2px solid ${({ theme }) => theme.colors.gray400};
  &:before {
    content: '';
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 50%;
    position: absolute;
    z-index: 1;
    visibility: hidden;
    transition: all 0.2s ease;
  }
  &:after {
    content: '';
    display: block;
    width: 8px;
    height: 4px;
    position: absolute;
    z-index: 2;
    visibility: hidden;
    transition: all 0.2s ease;
  }
`;

const Content = styled.div`
  margin-left: 13px;
`;

const Label = styled.label`
  display: flex;
  align-items: ${({ verticalAlign }) => verticalAlignOptions[verticalAlign]};
  color: ${props => getColor(props)};
  pointer-events: auto;
  ${Radio} {
    border-color: ${props => getColor(props)};
  }
  ${({ checked, indeterminate }) => (checked || indeterminate)
    && css`
      ${Radio} {
        border-color: ${props => getColor(props)};
        &:before {
          background: ${props => getColor(props)};
          visibility: visible;
        }
      }
    `}
  ${({ indeterminate }) => indeterminate
    && css`
      ${Radio} {
        &:after {
          background: ${({ theme }) => theme.colors.white};
          visibility: visible;
        }
      }
    `}
  ${({ disabled }) => disabled
    && css`
      cursor: not-allowed;
    `}
  ${({ pointer, disabled }) => pointer
    && !disabled
    && css`
      cursor: pointer;
    `}
`;

function RadioButton({
  checked,
  indeterminate,
  disabled,
  children,
  onChange,
  ...rest
}) {
  function handleOnChange(e) {
    if (!disabled && onChange) {
      onChange(e);
    }
  }

  return (
    <Label
      role="radio"
      aria-checked={checked}
      checked={checked}
      indeterminate={indeterminate}
      disabled={disabled}
      onClick={handleOnChange}
      pointer={!!onChange}
      {...rest}
    >
      <Radio />
      {children && <Content>{children}</Content>}
    </Label>
  );
}

RadioButton.displayName = 'RadioButton';

RadioButton.propTypes = {
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  onChange: PropTypes.func,
  verticalAlign: PropTypes.oneOf(['center', 'top', 'bottom']),
};

RadioButton.defaultProps = {
  checked: false,
  indeterminate: false,
  disabled: false,
  children: null,
  onChange: null,
  verticalAlign: 'center',
};

export default RadioButton;
