import styled from "styled-components";

export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const Container = styled.div`
  display: flex;
  justify-content: center;
  max-width: ${({ fullWidth }) => (fullWidth ? "unset" : "365px")};
  width: 100%;
  padding: 0 16px;
  margin: 32px auto 50px;
`;
