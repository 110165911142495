import styled, { css } from 'styled-components';
import handleVariants from '../helpers/handleVariants';

const Input = styled.input`
  color: ${({ variant, theme }) => handleVariants(variant, theme, theme.colors.gray500)};
  font-weight: 500;
  transition: all 0.2s;
  border: 0;
  border-bottom: 2px solid
    ${({ variant, theme }) => handleVariants(variant, theme, theme.colors.gray100)};
  appearance: none;
  border-radius: 0;
  outline: 0;
  width: 100%;
  padding: 0 0 0.7rem 0.5rem;
  background: ${props => (props.variant === 'light' ? 'transparent' : '')};
  ${({ as }) => as === 'input'
    && css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}
  &::placeholder {
    color: ${({ variant, theme }) => handleVariants(variant, theme, theme.colors.gray300)};
  }
  &:not(:placeholder-shown) {
    &:valid {
      border-color: ${({ variant, theme }) => handleVariants(variant, theme, theme.colors.primary)};
    }
    &:invalid {
      border-color: ${({ variant, theme }) => handleVariants(variant, theme, theme.colors.danger)};
    }
  }
  &:focus,
  &:focus:valid,
  &:focus:invalid {
    border-color: ${({ theme }) => theme.colors.secondary};
  }
  &:disabled {
    background: transparent;
    border-color: ${({ theme }) => theme.colors.gray100};
    color: ${({ theme }) => theme.colors.gray100};
    cursor: not-allowed;
  }
  &.invalid {
    border-color: ${({ variant, theme }) => handleVariants(variant, theme, theme.colors.danger)} !important;
  }
  &.valid {
    border-color: ${({ variant, theme }) => handleVariants(variant, theme, theme.colors.primary)} !important;
  }
`;

Input.displayName = 'Input';

export default Input;
