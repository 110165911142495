import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import FontAwesomeIcon from '../elements/FontAwesomeIcon';
import Grid from '../elements/Grid';

export const STATUS_CODE = {
  500: 'Conteúdo indisponível no momento.',
  502: 'Estamos passando por instabilidades, tente novamente em alguns instantes',
  400: 'Alguma coisa deu errado, tente novamente em alguns instantes',
  404: 'Não conseguimos encontrar o que você estava procurando.',
};

const mediaQuery = css`
  @media (min-width: ${({ theme }) => theme.devices.mobile}) {
    max-width: ${({ theme }) => theme.devices.mobile};
  }

  @media (min-width: ${({ theme }) => theme.devices.tablet}) {
    max-width: ${({ theme }) => theme.devices.tablet};
  }

  @media (min-width: ${({ theme }) => theme.devices.desktop}) {
    max-width: ${({ theme }) => theme.devices.desktop};
  }

  @media (min-width: 1200px) {
    max-width: 1140px;
  }
`;

const Body = styled.article`
  width: 100%;
  max-width: 1140px;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 30px 25px;
  margin-bottom: ${props => (props.noMargin ? 0 : '30px')};
  margin-top: calc(10px * ${({ paddingTop }) => paddingTop});
  margin-left: auto;
  margin-right: auto;

  ${props => props.noMargin
    && `
    padding: 0;
  `};

  ${mediaQuery};
`;
const Content = styled.div`
  width: 100%;
`;

const GroupIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 16px;

  i {
    color: ${({ theme }) => theme.colors.gold};
  }
`;
const Legend = styled.legend`
  font-size: 17px;
  margin-bottom: 0;
  font-weight: 300;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray200};
`;

const Hr = styled.hr`
  width: 100%;
  height: 2px;
  border: none;
  margin: 10px 0 40px 0;
  background-color: ${({ theme }) => theme.colors.gray100};

  ${props => props.inverse
    && `
    background-color: ${props.theme.colors.white};
  `};
`;

const H2 = styled.h2`
  color: ${({ theme }) => theme.colors.gold};
  font-family: ${({ theme }) => theme.fonts.family.montserrat};
  font-size: 17px;
  font-weight: 600;
`;

function Title({ children }) {
  return (
    <Grid
      direction="column"
      verticalAlign="flex-start"
      data-testid="test-title"
    >
      <H2 data-testid="test-title-heading">{children}</H2>
      <Hr />
    </Grid>
  );
}

Title.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function DisplayError({
  icon, title, paddingTop, children, ...rest
}) {
  return (
    <Body data-testid="test-display-error" paddingTop={paddingTop} {...rest}>
      {title === '' || !title ? null : <Title>{title}</Title>}
      <Grid direction="column">
        <GroupIcon>
          <FontAwesomeIcon icon={icon} size="5x" />
        </GroupIcon>
        <Content>
          <Legend>{children}</Legend>
        </Content>
      </Grid>
    </Body>
  );
}

DisplayError.defaultProps = {
  icon: ['fal', 'frown'],
  title: '',
  paddingTop: 0,
};

DisplayError.propTypes = {
  icon: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]),
  title: PropTypes.string,
  paddingTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default DisplayError;
