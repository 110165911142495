export const breakpoints = {
  XS: '579px',
  SM: '768px',
  MD: '1024px',
  LG: '1200px',
};

export function isMinDevice(breakpoint) {
  let point = breakpoint || '';
  point = point.toUpperCase();
  return window.matchMedia(`(min-width: ${breakpoints[point]})`).matches;
}

export function isMaxDevice(breakpoint) {
  let point = breakpoint || '';
  point = point.toUpperCase();
  return window.matchMedia(`(max-width: ${breakpoints[point]})`).matches;
}
