import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Styles
const NavLink = styled(Link)`
  display: block;
  padding: 1.25rem 1rem;
  color: #758393;
  border-bottom: 3px solid transparent;
  opacity: 0.6;
  font-weight: bold;
  font-size: 0.75rem;
  text-decoration: none;
  text-transform: uppercase;

  &.disable {
    pointer-events: none;
  }

  &.active {
    opacity: 1;
    pointer-events: none;
    border-bottom-color: #ffb800;
  }

  &:hover {
    opacity: 0.8;
    color: #758393;
    text-decoration: none;
  }
`;

function MenuItem({ item, ...rest }) {
  return (
    <li>
      <NavLink
        to={item.href}
        data-to={item.href}
        data-analitycs={item.datalayer}
        {...rest}
      >
        {item.name}
      </NavLink>
    </li>
  );
}

MenuItem.propTypes = {
  item: PropTypes.shape({
    name: PropTypes.string.isRequired,
    href: PropTypes.string.isRequired,
    datalayer: PropTypes.string.isRequired,
  }).isRequired,
};

export default MenuItem;
