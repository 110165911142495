export default function handleVariants(variant, theme, color) {
  switch (variant) {
    case 'light':
      return theme.colors.white;
    case 'primary':
      return color;
    default:
      return color || theme.colors.gray500;
  }
}
