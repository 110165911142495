import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { Button as ButtonReactBootstrap } from 'react-bootstrap';

export function convertHex(hex) {
  if (hex.length < 7) {
    return hex;
  }
  const color = hex.replace('#', '');
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);

  return `rgba(${r}, ${g}, ${b}, .5)`;
}

function btnCss(
  backgroundColor,
  backgroundColorHover,
  fontColor,
  outline = false,
  fontColorHover = '#FFF',
) {
  return css`
    ${!outline ? `background: ${backgroundColor};` : ''}
    border-color: ${backgroundColor};
    color: ${fontColor || backgroundColor};

    &:hover {
      border-color: ${backgroundColorHover};
      color: ${fontColorHover};
      background: ${backgroundColorHover};
    }

    &:focus,
    &:active {
      box-shadow: 0 0 0 0.2rem ${convertHex(backgroundColor)} !important;
    }
  `;
}

const buttons = [
  'institucional',
  'alimentacao',
  'refeicao',
  'auto',
  'transporte',
  'cultura',
  'saude',
  'compras',
  'negativo',
  'disabled',
];

const ButtonReactBootstrapCustom = styled(ButtonReactBootstrap)`
  &.btn-xs {
    padding: 0.25rem 0.5625rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    letter-spacing: 0;
  }

  &.btn-sm {
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    font-size: 0.75rem;
    letter-spacing: 0;
  }

  &.btn-md {
    padding: 0.875rem 1rem;
    border-radius: 0.5rem;
    font-size: 0.9375rem;
    line-height: 1.5;
    letter-spacing: 0;
  }

  &.btn-lg {
    padding: 1rem;
    border-radius: 0.375rem;
    font-size: 0.9375rem;
    line-height: 1.5;
    letter-spacing: 0;
  }

  &.btn-xl {
    padding: 1.5rem 1.75rem;
    border-radius: 0.375rem;
    font-size: 0.9375rem;
    line-height: 1.5;
  }

  ${buttons.map(
    button => css`
      &.btn-${button} {
        ${props => btnCss(
    props.theme.colors[button],
    props.theme.colors[`${button}Dark`],
    props.theme.colors[`${button}Color`] || props.theme.colors.white,
    props.theme.colors[button.outline],
    props.theme.colors[`${button}FontHover`],
  )};
      }
      &.btn-outline-${button} {
        ${props => btnCss(
    props.theme.colors[button],
    props.theme.colors[`${button}Dark`],
    null,
    true,
  )};
      }
    `,
  )}

  &.btn-link-vr {
    color: ${props => props.theme.colors.primary};
  }
`;

function Button({ buttonType, ...rest }) {
  return <ButtonReactBootstrapCustom type={buttonType} {...rest} />;
}

const variants = [
  'primary',
  'secondary',
  'success',
  'warning',
  'danger',
  'info',
  'light',
  'dark',
  'link',
  'negativo',
  'disabled',
  'transparent',

  'institucional',
  'alimentacao',
  'refeicao',
  'auto',
  'transporte',
  'cultura',
  'saude',
  'compras',
  'link-vr',

  'outline-primary',
  'outline-secondary',
  'outline-success',
  'outline-warning',
  'outline-danger',
  'outline-info',
  'outline-light',
  'outline-dark',

  'outline-institucional',
  'outline-alimentacao',
  'outline-refeicao',
  'outline-auto',
  'outline-transporte',
  'outline-cultura',
  'outline-saude',
  'outline-compras',
];

const sizes = ['xs', 'sm', 'md', 'lg', 'xl'];

const types = ['button', 'submit', 'reset'];

Button.propTypes = {
  active: PropTypes.bool,
  block: PropTypes.bool,
  href: PropTypes.string,
  disabled: PropTypes.bool,
  buttonType: PropTypes.oneOf(types),
  variant: PropTypes.oneOf(variants),
  size: PropTypes.oneOf(sizes),
  children: PropTypes.oneOfType([PropTypes.any]),
};

Button.defaultProps = {
  active: false,
  block: false,
  href: null,
  disabled: false,
  buttonType: 'button',
  variant: 'primary',
  size: 'md',
  children: null,
};

Button.displayName = 'Element Button';

export default Button;
