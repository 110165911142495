import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const normalizeIcon = (icon) => {
  let classes = '';

  if (typeof icon === 'object' && icon.prefix && icon.iconName) {
    classes = icon;
  }

  if (Array.isArray(icon) && icon.length === 2) {
    classes = { prefix: icon[0], iconName: icon[1] };
  }

  if (typeof icon === 'string') {
    classes = { prefix: 'fal', iconName: icon };
  }

  return `${classes.prefix} fa-${classes.iconName}`;
};

const normalizeClassName = className => (className ? className.split(' ') : []);

const classList = (properties) => {
  const classes = {
    'fa-spin': properties.spin,
    'fa-inverse': properties.inverse,
    'fa-fw': properties.fixedWidth,
    'fa-border': properties.border,
    'fa-pulse': properties.pulse,
    [`fa-flip-${properties.flip}`]: properties.flip,
    [`fa-rotate-${properties.rotation}`]: properties.rotation,
    [`fa-pull-${properties.pull}`]: properties.pull,
    [`fa-${properties.size}`]: properties.size,
  };

  return Object.keys(classes)
    .map(key => (classes[key] ? key : null))
    .filter(key => key);
};

const getOtherProps = (properties) => {
  const execptionList = [
    'spin',
    'inverse',
    'fixedWidth',
    'border',
    'pulse',
    'flip',
    'rotation',
    'pull',
    'size',
  ];
  return Object.keys(properties)
    .filter(key => execptionList.indexOf(key) < 0)
    .reduce((props, key) => ({ ...props, [key]: properties[key] }), {});
};

const Icon = styled.i`
  color: ${props => props.color};
  vertical-align: baseline;
`;

const FontAwesomeIcon = ({
  className, icon, color, ...rest
}) => (
  <Icon
    className={[
      normalizeIcon(icon),
      ...classList(rest),
      ...normalizeClassName(className),
    ].join(' ')}
    color={color}
    aria-hidden="true"
    {...getOtherProps(rest)}
  />
);

FontAwesomeIcon.defaultProps = {
  size: null,
  className: '',
  color: 'inherit',
  fixedWidth: false,
  inverse: false,
  spin: false,
  border: false,
  pulse: false,
  pull: null,
  rotation: null,
  flip: null,
};

FontAwesomeIcon.propTypes = {
  size: PropTypes.oneOf([
    'lg',
    'xs',
    'sm',
    '1x',
    '2x',
    '3x',
    '4x',
    '5x',
    '6x',
    '7x',
    '8x',
    '9x',
    '10x',
  ]),
  className: PropTypes.string,
  color: PropTypes.string,
  fixedWidth: PropTypes.bool,
  inverse: PropTypes.bool,
  spin: PropTypes.bool,
  border: PropTypes.bool,
  pulse: PropTypes.bool,
  pull: PropTypes.oneOf(['right', 'left']),
  rotation: PropTypes.oneOf([90, 180, 270]),
  flip: PropTypes.oneOf(['horizontal', 'vertical', 'both']),
  icon: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.array,
    PropTypes.string,
  ]).isRequired,
};

export default FontAwesomeIcon;
