import { encode } from 'js-base64';

let LoginUrl =  "https://api.code7vr.com";

async function login(email: String, password: String, sessionId: String, tipoBot: String, ambiente: String) {
    if(ambiente == 'hml'){
      LoginUrl = 'https://apicandidate.code7vr.com';
    }
    const response = await fetch(`${LoginUrl}/login`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "auth": encode(`${email}:${password}`),
        "sessionId": sessionId,
        "tipoBot": tipoBot,
        "login": email,
      })
    });

    const data = await response.json();
    
    return data;
    
}

export default login