import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export function toCssClasses(numbers = '12') {
  const cols = numbers ? numbers.split(' ') : [];
  let classes = '';

  if (cols[0]) classes += `col-xs-${cols[0]}`;
  if (cols[1]) classes += ` col-sm-${cols[1]}`;
  if (cols[2]) classes += ` col-md-${cols[2]}`;
  if (cols[3]) classes += ` col-lg-${cols[3]}`;

  return classes;
}

const Div = styled.div`
  justify-content: ${({ textAlign }) => textAlign};

  ${({ verticalAlign }) => verticalAlign
    && `
    display: flex;
    align-items: ${verticalAlign};
  `};
  flex-direction: ${({ direction }) => direction};

  ${({ noPadding }) => noPadding
    && `
    padding-left: 0;
    padding-right: 0;
  `};
`;

function Grid({
  cols,
  className,
  children,
  paddingTop,
  noMargin,
  verticalAlign,
  textAlign,
  direction,
  ...rest
}) {
  return (
    <Div
      noMargin={noMargin}
      verticalAlign={verticalAlign}
      textAlign={textAlign}
      className={`${toCssClasses(cols)} ${className}`}
      direction={direction}
      role="gridcell"
      {...rest}
    >
      {children}
    </Div>
  );
}

Grid.defaultProps = {
  className: null,
  cols: '12 12 12 12',
  noMargin: false,
  paddingTop: 0,
  verticalAlign: 'center',
  textAlign: 'center',
  direction: 'row',
};

Grid.propTypes = {
  noMargin: PropTypes.bool,
  className: PropTypes.string,
  direction: PropTypes.oneOf(['column', 'row']),
  textAlign: PropTypes.oneOf(['center', 'flex-start', 'flex-end']),
  verticalAlign: PropTypes.oneOf(['center', 'flex-start', 'flex-end']),
  paddingTop: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cols: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export default Grid;
