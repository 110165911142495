import React from 'react';
import styled from 'styled-components';
import { LogoMd } from '../assets';

const Image = styled.img`
  width: 60px;
  height: 60px;
  margin: 30px auto;
`;

export default function LogoVr(props) {
  return (
    <Image
      {...props}
      src={LogoMd}
      alt="Logo com fundo verde da VR Benefícios, com VR escrito em branco e centralizado e a palavra benefícios em branco na parte inferior."
    />
  );
}
