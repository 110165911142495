import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components';

const scaleAnimation = keyframes`
  0%,
  40%,
  100% {
    -webkit-transform: scale(.4);
    -moz-transform: scale(.4);
    transform: scale(.4);
  }
  20% {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    transform: scale(1);
  }
`;

function loaderSize(size) {
  const sizes = { sm: 1, md: 2, lg: 3 };
  return css`
    width: calc((10px * ${sizes[size]}) * 3);
    height: calc(10px * ${sizes[size]});
  `;
}

const LoaderContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;
  justify-content: center;
  padding: 40px 20px;
`;
const LoaderCircle = styled.div`
  display: flex;
  justify-content: space-around;
  position: relative;
  font-size: 0;
  color: white;

  ${props => loaderSize(props.size)};
`;
const Circle = styled.span`
  background: ${props => props.theme.colors[props.color]};
  border: 1px solid ${props => props.theme.colors[props.color]};
  border-radius: 50%;
  display: block;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: ${scaleAnimation};
  animation-timing-function: linear;
  animation-iteration-count: infinite;

  &:nth-child(1) {
    animation-duration: 0.75s;
    animation-delay: 0;
  }
  &:nth-child(2) {
    animation-duration: 0.75s;
    animation-delay: 0.12s;
  }
  &:nth-child(3) {
    animation-duration: 0.75s;
    animation-delay: 0.24s;
  }

  ${props => loaderSize(props.size)};
`;

const total = 3;
const items = Array.from(Array(total).keys());

function LoaderDefault({ size, color, ...rest }) {
  return (
    <LoaderContainer size={size} color={color} {...rest}>
      <LoaderCircle size={size}>
        {items.map(item => (
          <Circle key={item} size={size} color={color} />
        ))}
      </LoaderCircle>
    </LoaderContainer>
  );
}

LoaderDefault.defaultProps = {
  size: 'md',
  color: 'primary',
};

LoaderDefault.propTypes = {
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  color: PropTypes.oneOf(['primary', 'secondary', 'white', 'black']),
};

LoaderDefault.displayName = 'LoaderDefault';

export default LoaderDefault;
