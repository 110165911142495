import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { Button as ButtonMUI } from "@material-ui/core";

const colors = (theme) => ({
  primary: theme.colors.secondary,
  primaryHover: theme.colors.institucionalComplementDark,
  secondary: theme.colors.white,
  secondaryHover: theme.colors.gray100,
  institutional: theme.colors.institucional,
  institutionalHover: theme.colors.institucionalDark,
});

const bgColors = (theme) => ({
  primary: 'green',
  primaryHover: 'white',
  secondary: 'white',
  secondaryHover: 'white',
  institutional: 'green',
  institutionalHover: 'green',
});

const bgColorsContrast = (theme) => ({
  primary: 'green',
  primaryHover: 'green',
});

const fontColors = (theme) => ({
  primary: 'white',
  primaryHover: 'green',
  secondary: 'green',
  secondaryHover: 'green',
  institutional: 'white',
  institutionalHover: 'white',
});

const fontColorsContrast = (theme) => ({
  primary: 'white',
  primaryHover: 'white',
});

const contained = css`
  background: ${({ theme, componentColor, contrast }) =>
    contrast
      ? bgColorsContrast(theme)[componentColor]
      : bgColors(theme)[componentColor]};
  color: ${({ theme, componentColor, contrast }) =>
    contrast
      ? fontColorsContrast(theme)[componentColor]
      : fontColors(theme)[componentColor]};

  &:hover {
    background: ${({ theme, componentColor, contrast }) =>
      contrast
        ? bgColorsContrast(theme)[`${componentColor}Hover`]
        : bgColors(theme)[`${componentColor}Hover`]};
    color: ${({ theme, componentColor, contrast }) =>
      contrast
        ? fontColorsContrast(theme)[`${componentColor}Hover`]
        : fontColors(theme)[`${componentColor}Hover`]};
  }

  &:disabled {
    background: 'gray';
    color: 'white';
    cursor: not-allowed;
    pointer-events: all !important;

    &:hover {
      background: 'gray';
    }
  }
`;

const outlined = css`
  border: 2px solid
    ${({ theme, componentColor }) => colors(theme)[componentColor]} !important;
  color: ${({ theme, componentColor }) =>
    colors(theme)[componentColor]} !important;

  &:disabled {
    border-width: 2px;
    cursor: not-allowed;
    pointer-events: all !important;
  }
`;

const transparent = css`
  color: 'white';
  font-weight: normal;

  &:disabled {
    color: 'white';
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: all !important;
  }
  &:hover {
    background: transparent;
  }
`;

const ComponentStyled = styled(
  ({ componentVariant, componentColor, contrast, ...rest }) => (
    <ButtonMUI {...rest} />
  )
)`
  &.MuiButtonBase-root {
    text-transform: none;
    font-weight: 600;
    letter-spacing: 0.4px;
    padding: ${({ size }) => !size && "14px 28px"};
    border-radius: 5px;
    outline: none;
    max-width: ${({ fullWidth }) => (fullWidth ? "unset" : "333px")};
    ${({ componentVariant }) => {
      switch (componentVariant) {
        case "contained":
          return contained;
        case "outlined":
          return outlined;
        case "transparent":
          return transparent;
        default:
          return contained;
      }
    }}
  }

  .MuiButton-iconSizeMedium > *:first-child {
    font-size: 16px;
  }
`;

const Button = ({
  variant = "contained",
  color = "primary",
  label,
  contrast,
  disabled,
  ...props
}) => {
  return (
    // https://material-ui.com/components/buttons/
    <ComponentStyled
      componentColor={color}
      componentVariant={disabled ? "contained" : variant}
      variant={variant === "outlined" && !disabled ? variant : undefined}
      contrast={contrast}
      disabled={disabled}
      {...props}
    >
      {label}
    </ComponentStyled>
  );
};

Button.propTypes = {
  label: PropTypes.string,
  variant: PropTypes.string,
  color: PropTypes.string,
  contrast: PropTypes.bool,
  disabled: PropTypes.bool,
  fullWidth: PropTypes.bool,
};

export default Button;
