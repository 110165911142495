import styled from 'styled-components';

const H2 = styled.h2`
  line-height: 1.2;
  font-size: 26px;
  font-weight: 700;
  color: ${({ theme, color }) => theme.colors[color]};
`;

export default H2;
