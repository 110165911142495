import React from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form";
import emailValidate from "../utils/devkit/validators/emailValidate";
import { Button, TextField } from "../elements/";

import { ContainerButton, Actions, InputContainer } from "./common";
import getErrorMsg from "../helpers/errorMessages";

export default function LoginForm({ onSubmit }) {
  const { handleSubmit, errors, formState, ...formControl } = useForm({
    mode: "onChange",
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <TextField
        fullWidth
        label="E-mail cadastrado no site / portal VR *"
        name="email"
        type="email"
        rules={{
          required: true,
          validate: (val) => {
            return emailValidate(val);
          },
        }}
        formControl={formControl}
        error={!!errors.email}
        caption={getErrorMsg(errors, "email")}
      />
      <InputContainer>
        <TextField
          fullWidth
          label="Senha utilizada no site / portal VR *"
          name="password"
          rules={{
            required: true,
          }}
          type="password"
          formControl={formControl}
          error={!!errors.password}
          caption={getErrorMsg(errors, "password")}
        />
      </InputContainer>
      <Actions>
        <ContainerButton>
          <Button
            label="Fazer login"
            type="submit"
            variant="contained"
            disabled={!formState.isValid}
          />
        </ContainerButton>
      </Actions>
    </form>
  );
}

LoginForm.propTypes = {
  onSubmit: PropTypes.func,
};
