import styled from 'styled-components';

const MessageError = styled.span`
  color: #fff;
  font-size: 0.66rem;
  padding: 3px 6px;
  font-weight: 500;
  letter-spacing: 0;
  margin-top: 9.5px;
  background: ${({ theme }) => theme.colors.danger};
`;

MessageError.displayName = 'MessageError';

export default MessageError;
