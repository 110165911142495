import React from 'react';
import PropTypes from 'prop-types';
import styled, { keyframes, css } from 'styled-components';

const ballSpin = keyframes`
  0%, 100% {
    opacity: 1;
    transform: scale(1);
  }
  20% {
    opacity: 1;
  }
  80% {
    opacity: 0;
    transform: scale(0);
  }
`;

function loaderSize(size) {
  const sizes = { sm: 1, md: 2, lg: 3 };

  return css`
    width: calc((16px * ${sizes[size]}));
    height: calc(16px * ${sizes[size]});
  `;
}

function loaderPosition(size) {
  const sizes = { sm: 1, md: 2, lg: 4 };

  return css`
    width: calc(4px * ${sizes[size]});
    height: calc(4px * ${sizes[size]});
    margin-top: calc(2px * ${-sizes[size]});
    margin-left: calc(2px * ${-sizes[size]});
  `;
}

const LoaderContainer = styled.div`
  width: 100%;
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  min-height: ${props => props.limit};
`;

const Inner = styled.div`
  font-size: 0;
  color: #fff;
  display: block;
  position: relative;
  box-sizing: border-box;

  ${props => loaderSize(props.size)};
`;
const Circle = styled.div`
  top: 50%;
  left: 50%;
  float: none;
  border-radius: 100%;
  position: absolute;
  box-sizing: border-box;
  display: inline-block;
  background: ${props => props.theme.colors[props.color]};
  animation: ${ballSpin} 1s infinite ease-in-out;

  ${props => loaderPosition(props.size)};

  &:nth-child(1) {
    top: 5%;
    left: 50%;
    animation-delay: -1.125s;
  }

  &:nth-child(2) {
    top: 18.1801948466%;
    left: 81.8198051534%;
    animation-delay: -1.25s;
  }

  &:nth-child(3) {
    top: 50%;
    left: 95%;
    animation-delay: -1.375s;
  }

  &:nth-child(4) {
    top: 81.8198051534%;
    left: 81.8198051534%;
    animation-delay: -1.5s;
  }
  &:nth-child(5) {
    top: 94.9999999966%;
    left: 50.0000000005%;
    animation-delay: -1.625s;
  }

  &:nth-child(6) {
    top: 81.8198046966%;
    left: 18.1801949248%;
    animation-delay: -1.75s;
  }

  &:nth-child(7) {
    top: 49.9999750815%;
    left: 5.0000051215%;
    animation-delay: -1.875s;
  }
  &:nth-child(8) {
    top: 18.179464974%;
    left: 18.1803700518%;
    animation-delay: -2s;
  }
`;

const total = 8;
const items = Array.from(Array(total).keys());

const LoaderCircle = ({ limit, color, size }) => (
  <LoaderContainer limit={limit} size={size} color={color}>
    <Inner size={size}>
      {items.map(item => (
        <Circle key={item} size={size} color={color} />
      ))}
    </Inner>
  </LoaderContainer>
);

LoaderCircle.defaultProps = {
  limit: '250px',
  size: 'md',
  color: 'primary',
};

LoaderCircle.propTypes = {
  limit: PropTypes.string,
  size: PropTypes.oneOf(['sm', 'md', 'lg']),
  color: PropTypes.oneOf(['primary', 'secondary', 'white', 'black']),
};

LoaderCircle.displayName = 'LoaderCircle';
Circle.displayName = 'LoaderCircleItem';
Inner.displayName = 'LoaderCircleInner';
LoaderContainer.displayName = 'LoaderCircleContainer';

export default LoaderCircle;
