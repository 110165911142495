import React, { useState, useContext } from "react";
import styled from "styled-components";
import LoginForm from "../components/LoginForm";
import { LogoVr } from "../elements";
import Loader from "../components/Loader";
import { Container, PageContainer } from "./common";
import login from "../services/login/login";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const DEFAULT_ERROR_MESSAGE = "Infelizmente não foi possível efetuar seu login 🙁";
const URL_PORTAL_CADASTRO = 'https://portal.vr.com.br/portal/portal-vr/login/cadastre-se-vr-beneficios.html';
const URL_PORTAL_ESQUECEU = 'https://portal.vr.com.br/portal/portal-vr/login/recuperar-senha-de-acesso-vr-beneficios.html';
const errorLogin = (errorMessage) => {
  toast.error(errorMessage)
};

const sucessoLogin = () => {
  toast.success("Login efetuado com sucesso 🙂")
};

const LoginContainer = styled(PageContainer)`
  position: relative;
  flex-direction: column;
  align-items: center;
  padding-bottom: 15px;
  margin-top: 30px;
  @media (min-width: 200px) {
    margin-top: 0;
    padding-bottom: 35px;
    box-sizing: border-box;
    height: 100vh;
    justify-content: center;
  }
`;
const FormContainer = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 60px auto 0;
  max-width: 335px;
`;
const LogadoContainer = styled(Container)`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 60px auto 0;
  max-width: 335px;
`;

const Welcome = styled.p`
  border: 1px solid #ffc600;
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  color: white;
  text-align: center;
  margin: -20px 0 40px 0;`

const IDontHaveLoginYet = styled.a`
  font-size: 12px;  
  color: white;
  text-decoration: none;
  text-align: center;
  margin: 5px 20px;
`


export default function Login() {
  const [isLoading, setIsLoading] = useState(false);
  const [isLoged, setIsLoged] = useState(false);

  function handleLoginSuccess(data) {
    if (data.success) {
      setIsLoged(true);
      sucessoLogin();
    } else {
      setIsLoged(false);
      const errorMessage = !!data.message ? data.message : DEFAULT_ERROR_MESSAGE;
      errorLogin(errorMessage);
    }
  }

  function handleLoginError(errorMessage) {
    setIsLoged(false);
    errorLogin(errorMessage);
  }

  async function handleSubmit(payload) {
    const {email, password} = payload;
    let ambiente = '';
    let sessionId;
    const path = window.location.pathname ? window.location.pathname.split('/') : '';

    const tipoBot = path[1];

    if(path[2] == 'hml'){
      ambiente = path[2];
      sessionId = path[3];
    }else{
      sessionId = path[2];
    }
    
    

    setIsLoading(true);

    login( email, password, sessionId, tipoBot, ambiente)
    .then(response  => handleLoginSuccess(response))
    .catch(error    => handleLoginError(DEFAULT_ERROR_MESSAGE))

    setIsLoading(false);
  }

  return (
    <Loader isLoading={isLoading}>
      <LoginContainer className={isLoged ? 'esconde' : 'exibe'}>
      <LogoVr />
        <FormContainer>
          <Welcome> 
            Olá, após fazer seu login abaixo, você poderá fazer suas consultas.
          </Welcome>
          <LoginForm onSubmit={handleSubmit} />
          <IDontHaveLoginYet href={URL_PORTAL_ESQUECEU}>Esqueci minha senha.</IDontHaveLoginYet>
          <IDontHaveLoginYet href={URL_PORTAL_CADASTRO}>Ainda não sou um cliente VR.</IDontHaveLoginYet>
        </FormContainer>
      </LoginContainer>
      <LoginContainer className={!isLoged ? 'esconde' : 'exibe'}>
        <LogadoContainer> 
        <LogoVr />
          <Welcome> 
            Login foi efetuado com sucesso!
            <br/>
            Volte ao whatsapp e continue sua jornada 🙂
          </Welcome>
        </LogadoContainer>
      </LoginContainer>
      <ToastContainer />
    </Loader>
  );
}
