import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { SelectContext } from './Select';

const Container = styled.li.attrs({
  role: 'option',
})`
  display: flex;
  align-items: center;
  padding: 0.8rem 1.33rem;
  border-bottom: 2px solid ${({ theme }) => theme.colors.gray100};
  font-weight: 500;
  transition: all 0.2s ease;
  cursor: pointer;
  ${({ selected, theme }) => selected
    && css`
      color: ${theme.colors.white};
      background: ${theme.colors.primary};
    `}
  ${({ disabled, theme }) => (disabled
    ? css`
          cursor: not-allowed;
          color: ${theme.colors.gray100};
        `
    : css`
          &:hover {
            color: ${theme.colors.white};
            background: ${theme.colors.primary};
          }
        `)}
  i {
    margin-right: 1rem;
  }
`;

function Option({
  disabled, value, children, onClick, ...rest
}) {
  const selectContext = useContext(SelectContext);
  const isSelected = !disabled && (value || value === 0) && value === selectContext.value;

  function handleOnClick(e) {
    if (disabled) return;

    const { target } = e;

    const item = {
      label: children,
      selected: isSelected,
      disabled,
      value,
      children,
      target,
    };

    if (selectContext.onSelect) selectContext.onSelect(item);
    if (onClick) onClick(e);
  }

  return (
    <Container
      onClick={handleOnClick}
      selected={isSelected}
      disabled={disabled}
      value={value}
      {...rest}
    >
      {children}
    </Container>
  );
}

Option.displayName = 'Option';

Option.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Option.defaultProps = {
  disabled: false,
  children: null,
  onClick: null,
  value: null,
};

export default Option;
