import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import MenuItem from '../elements/MenuItem';

function isMobile() {
  if (
    navigator.userAgent.match(/Android/i)
    || navigator.userAgent.match(/webOS/i)
    || navigator.userAgent.match(/iPhone/i)
    || navigator.userAgent.match(/iPad/i)
    || navigator.userAgent.match(/iPod/i)
    || navigator.userAgent.match(/BlackBerry/i)
    || navigator.userAgent.match(/Windows Phone/i)
  ) {
    return true;
  }

  return false;
}

const Section = styled.section`
  width: 100%;
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  background-color: #fff;
  height: 62px;
  position: relative;
  border-bottom: 1px solid ${({ theme }) => theme.colors.gray100};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    max-width: ${({ theme }) => theme.tablet};
  }

  @media (min-width: ${({ theme }) => theme.desktop}) {
    max-width: ${({ theme }) => theme.desktop};
    flex-direction: row;

    .financeiro__button-sua-opiniao {
      margin-left: 0;
      margin-bottom: 0;
    }
  }
`;

const Container = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: flex-start;

  @media (max-width: ${props => (props.scrollbarVisibility
    ? props.scrollbarVisibility
    : props.theme.desktop)}) {
    white-space: nowrap;
    overflow-y: hidden;
    overflow-x: ${isMobile() ? 'scroll' : 'hidden'};
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    transition: all 0.2s;
    will-change: transform;
  }

  &.grabbing {
    cursor: grabbing;
    cursor: -webkit-grabbing;
    user-select: none;
  }
`;

const Nav = styled.nav`
  padding: 0;
  display: flex;
  user-select: none;
  list-style: none;
  flex: 1;

  @media (max-width: ${({ theme }) => theme.devices.desktop}) {
    margin-left: 0;

    li {
      white-space: nowrap;
    }
  }
`;

function Menu({ items, scrollbarVisibilityAt, ...rest }) {
  const [active, setActive] = useState('');
  const testid = Object.getOwnPropertyDescriptor(rest, 'data-testid')
    ? rest['data-testid']
    : 'menubar';
  const location = useLocation();
  const slider = useRef(null);
  const [isDown, setIsDown] = useState(false);
  const [moving, setMoving] = useState(false);
  const [startX, setStartX] = useState(0);

  function onHandleNavClick(event) {
    const { currentTarget } = event;
    const { to, analitycs } = currentTarget.dataset;

    if (!moving) {
      if (window.dataLayer) {
        window.dataLayer.push({
          event: 'virtualPageview',
          page: analitycs,
        });
      }

      setActive(to);

      currentTarget.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    } else {
      event.preventDefault();
    }
  }

  useEffect(() => {
    const { pathname } = location;

    const url = pathname.split('/');
    setActive(`/${url[1]}`);
  }, [setActive, location]);

  function onHandleMouseDown(event) {
    if (!isMobile()) {
      setIsDown(true);
      setStartX(event.clientX);

      // achar uma forma melhor de fazer isso
      slider.current.classList.add('grabbing');
    }
  }
  function onHandleMouseUp() {
    if (!isMobile()) {
      window.setTimeout(() => setMoving(false), 500);

      // achar uma forma melhor de fazer essa parte
      document
        .querySelectorAll('[role="menuitem"]')
        .forEach(item => item.classList.remove('disable'));

      // achar uma forma melhor de fazer isso
      slider.current.classList.remove('grabbing');

      setIsDown(false);
    }
  }
  function onHandleMouseMove(event) {
    if (isDown && !isMobile()) {
      const posX = startX - event.clientX;
      const node = slider.current;

      // achar uma forma melhor de fazer essa parte
      document
        .querySelectorAll('[role="menuitem"]')
        .forEach(item => item.classList.add('disable'));

      setMoving(true);
      setStartX(event.clientX);

      slider.current.scrollLeft = node.scrollLeft + posX;
    }
  }

  return (
    <Section
      role="menubar"
      data-testid={testid}
      scrollbarVisibility={`${scrollbarVisibilityAt}px`}
    >
      <Container
        data-testid={`${testid}-slider`}
        scrollbarVisibility={`${scrollbarVisibilityAt}px`}
        ref={slider}
      >
        <Nav
          role="menu"
          data-testid={`${testid}-nav`}
          onMouseDown={onHandleMouseDown}
          onMouseLeave={onHandleMouseUp}
          onMouseUp={onHandleMouseUp}
          onMouseMove={onHandleMouseMove}
        >
          {items.map(item => (
            <MenuItem
              key={item.id}
              item={item}
              role="menuitem"
              className={`${active === item.href ? 'active' : ''}`}
              data-testid={`${testid}-navitem`}
              onClick={onHandleNavClick}
            />
          ))}
        </Nav>
      </Container>
    </Section>
  );
}

Menu.defaultProps = {
  scrollbarVisibilityAt: null,
};

Menu.propTypes = {
  scrollbarVisibilityAt: PropTypes.number,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      href: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      datalayer: PropTypes.string.isRequired,
    }),
  ).isRequired,
};

export default Menu;
