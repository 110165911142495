import React, {
  useRef,
  useEffect,
  useCallback,
  forwardRef,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import VMasker from 'vanilla-masker';
import FontAwesomeIcon from '../elements/FontAwesomeIcon';
import Input from '../elements/Input';
import Textarea from '../elements/Textarea';
import Label from '../elements/Label';
import MessageError from '../elements/MessageError';

import mergeRefs from '../helpers/mergeRefs';
import handleVariants from '../helpers/handleVariants';

const Container = styled.div`
  position: relative;
  display: block;
  width: 100%;
  padding-top: 1.5rem;
  ${({ disabled }) => disabled
    && css`
      cursor: not-allowed;
    `}

  ${({ showPlaceholder }) => (showPlaceholder
    ? css`
          label {
            position: absolute;
            left: 0.5rem;
            top: 0;
            font-size: 0.8rem;
            font-weight: 600;
          }
        `
    : css`
          label {
            position: absolute;
            left: 0.5rem;
            top: 1.5rem;
          }
        `)}

  input,
  textarea {
    padding-right: 25px;
    &::placeholder {
      opacity: ${({ showPlaceholder }) => (showPlaceholder ? 1 : 0)};
    }
    &:placeholder-shown {
      opacity: 1;
    }
    &:focus,
    &:not(:placeholder-shown) {
      + label {
        top: 0;
        font-size: 0.8rem;
        font-weight: 600;
      }
    }
    &:focus {
      ~ i {
        opacity: ${props => (props.inputType === 'password' ? 1 : 0)};
      }
    }
  }
  i {
    position: absolute;
    top: 2rem;
    right: 0;
    opacity: 1;
  }

  @media (max-width: 1024px) {
    margin-bottom: 20px;
    label {
      font-size: 0.8rem;
    }
  }
`;

const IconContainer = styled.div`
  cursor: pointer;
`;

const Icon = styled(FontAwesomeIcon).attrs(({ variant, name, theme }) => {
  const color = name === 'times' ? theme.colors.danger : theme.colors.primary;

  return {
    icon: ['fas', name],
    color: handleVariants(variant, theme, color),
  };
})``;

const TextField = forwardRef(
  (
    {
      id,
      label,
      multiple,
      name,
      placeholder,
      messageError,
      disabled,
      uppercase,
      mask,
      maskMoney,
      maskPhone,
      onChange,
      onFocus,
      onBlur,
      className,
      variant,
      type,
      showPlaceholder,
      ...rest
    },
    ref,
  ) => {
    const inputRef = useRef(null);
    const iconRef = useRef(null);
    const [showPassword, setShowPassword] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const Component = multiple ? Textarea : Input;

    const applyMask = useCallback(
      (value) => {
        if (!value) return value;

        let maskedValue = value;
        if (maskMoney) {
          const customConfig = mask && typeof mask !== 'string' ? { ...mask } : {};

          const config = {
            precision: 2,
            separator: ',',
            delimiter: '',
            unit: 'R$',
            ...customConfig,
          };
          maskedValue = VMasker.toMoney(value, config);
        } else if (maskPhone) {
          const clearValue = String(value).replace(/[^0-9]/g, '');
          const pattern = clearValue.length <= 10 ? '(99) 9999-9999' : '(99) 99999-9999';
          maskedValue = VMasker.toPattern(value, pattern);
        } else if (mask) {
          maskedValue = VMasker.toPattern(value, mask);
        }
        return maskedValue;
      },
      [maskMoney, maskPhone, mask],
    );

    useEffect(() => {
      if (!inputRef.current) return;

      const maskedValue = applyMask(inputRef.current.value);

      if (inputRef.current.value !== maskedValue) {
        inputRef.current.value = maskedValue;
        if (onChange) {
          onChange({ target: inputRef.current });
        }
      }
    }, [inputRef.current, applyMask, onChange]);

    useEffect(() => {
      const clickingAgainOnInput = (event) => {
        if (!iconRef.current && inputRef.current === event.target) {
          setIsFocused(true);
        }
      };
      const clickingOutsideIcon = (event) => {
        if (iconRef.current && !iconRef.current.contains(event.target)) {
          setIsFocused(false);
        }
      };

      const handleClickOutside = (event) => {
        clickingOutsideIcon(event);
        clickingAgainOnInput(event);
      };

      document.addEventListener('mousedown', handleClickOutside);

      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [iconRef]);

    const handleOnChange = useCallback(
      (e) => {
        e.target.value = applyMask(e.target.value);

        if (onChange) {
          onChange(e);
        }
      },
      [onChange, applyMask],
    );

    const handleShowPasswordIcon = () => {
      const icon = showPassword ? 'eye' : 'eye-slash';
      const handleClick = () => {
        inputRef.current.type = inputRef.current.type === 'password' ? 'text' : 'password';
        setShowPassword(prevState => !prevState);
      };

      return (
        <IconContainer ref={iconRef}>
          <Icon onClick={handleClick} variant={variant} name={icon} />
        </IconContainer>
      );
    };

    const handleOnFocus = (event) => {
      if (!multiple) {
        setIsFocused(true);
      }

      if (onFocus) {
        onFocus(event);
      }
    };

    return (
      <Container
        inputType={type}
        className={className}
        disabled={disabled}
        showPlaceholder={showPlaceholder}
      >
        <Component
          ref={mergeRefs([inputRef, ref])}
          name={name}
          id={id || name}
          placeholder={placeholder || label}
          disabled={disabled}
          onChange={handleOnChange}
          className={messageError && 'invalid'}
          variant={variant}
          type={!multiple && type ? type : null}
          onFocus={handleOnFocus}
          onBlur={onBlur}
          {...rest}
        />
        <Label
          htmlFor={id || name}
          disabled={disabled}
          uppercase={uppercase}
          variant={variant}
        >
          {label}
        </Label>
        {!messageError
          && (rest.value || '').length > 0
          && type !== 'password' && <Icon variant={variant} name="check" />}
        {messageError && (
          <>
            <Icon variant={variant} name="times" />
            <MessageError id={`error-${name}`}>{messageError}</MessageError>
          </>
        )}
        {!messageError
          && (rest.value || '').length > 0
          && isFocused
          && type === 'password'
          && handleShowPasswordIcon()}
      </Container>
    );
  },
);

TextField.displayName = 'TextField';

TextField.propTypes = {
  /** id que será repassado ao elemento input */
  id: PropTypes.string,
  /** Texto que será exibido na label do componente */
  label: PropTypes.string,
  /** name que será repassado ao elemento input */
  name: PropTypes.string,
  /** Habilitando esta flag o campo a ser exibido passará a ser um <textarea> */
  multiple: PropTypes.bool,
  /** placeholder que será repassado ao elemento input */
  placeholder: PropTypes.string,
  /** Função onChange que será repassada ao elemento input */
  onChange: PropTypes.func,
  /** Mensagem de erro a ser exibida abaixo do campo */
  messageError: PropTypes.string,
  /** Desabilita a edição do componente */
  disabled: PropTypes.bool,
  /** Transforma o label do componente em caixa alta */
  uppercase: PropTypes.bool,
  /** Pattern a ser gerado a máscara no elemento. O patter segue o padrão do
   * Vanilla Masker e pode ser uma string ou um objeto */
  mask: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  /** Flag para habilitar a formatação de moeda. Formato padrão em reais. Para sobrescrever
   * as configurações padrões, basta passar um objeto com as configurações de moeda na prop mask */
  maskMoney: PropTypes.bool,
  /** Flag para habilitar a formatação de telefone, alternando entre fixo e celular
   * dependendo do tamanho do valor digitado */
  maskPhone: PropTypes.bool,
  /* Classe de CSS que será repassada ao container do componente */
  className: PropTypes.string,
  /* Variação nos estilos do componente */
  variant: PropTypes.string,
  /* Tipo do input */
  type: PropTypes.string,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  /* Exibir label e placeholder ao mesmo tempo */
  showPlaceholder: PropTypes.bool,
};

TextField.defaultProps = {
  id: '',
  label: '',
  name: '',
  placeholder: '',
  multiple: false,
  onChange: null,
  messageError: '',
  disabled: false,
  uppercase: false,
  mask: null,
  maskMoney: false,
  maskPhone: false,
  className: null,
  variant: 'primary',
  type: 'text',
  onBlur: null,
  onFocus: null,
  showPlaceholder: false,
};

export default TextField;
