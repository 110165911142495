import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';

import If from '../helpers/If';
import { isMaxDevice } from '../helpers/mediaQuery';

const StyledTable = styled.table`
  width: 100%;
  margin-bottom: 0;

  tbody {
    tr {
      ${props => props.striped
        && css`
          &:nth-child(even) {
            background: ${props.theme.colors.gray600};
          }

          &:nth-child(odd) {
            background: ${props.theme.colors.white};
          }
        `};

      ${props => props.dark
        && css`
          border-bottom: 1px solid ${props.theme.colors.gray100};

          &:last-child {
            border-bottom: none;
          }

          ${props => props.striped
            && css`
              &:nth-child(even) {
                background: ${props.theme.colors.gray600};
              }

              &:nth-child(odd) {
                background: ${props.theme.colors.white};
              }
            `};
        `};
    }
  }

  thead {
    ${props => props.isMaxDevice
      && css`
        display: none;
        visibility: hidden;
      `};

    tr {
      ${props => props.dark
        && css`
          background: ${props.theme.colors.gray100};
          border-bottom: 1px solid ${props.theme.colors.gray300};
        `};
    }

    th {
      ${props => props.dark
        && css`
          color: ${props.theme.colors.gray400};
        `};
    }
  }
`;

const TableHeadRow = styled.tr`
  background: ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.gray100};
`;

const alignText = css`
  ${props => props.alignText
    && css`
      text-align: ${props.alignText};
    `};

  &:first-child {
    text-align: left;
  }

  &:last-child {
    text-align: right;
  }
`;

const StyledTableHead = styled.th`
  border: none;
  font-size: 15px;
  color: ${props => props.theme.colors.gray400};
  padding: 5px 10px;
  text-align: left;
  border-bottom: none;
  font-weight: 500;

  ${alignText};
`;

const StyledTableRow = styled.tr`
  @media (max-width: ${props => props.theme.devices.desktop}) {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
  }
`;

const StyledTableData = styled.td`
  border: none;
  color: ${props => props.theme.colors.gray500};
  vertical-align: inherit;
  font-weight: 400;
  font-size: 14px;
  padding: 5px 10px;
  height: 50px;

  ${props => props.size === 'sm'
    && css`
      height: 35px;
    `};

  ${alignText};

  @media (max-width: ${props => props.theme.devices.desktop}) {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid ${props => props.theme.colors.gray300};

    ${props => props.details
      && css`
        &:last-child {
          border-bottom: 0;
          justify-content: center;
        }
      `};
  }
`;

export function TableHead({ alignText, children, ...rest }) {
  return (
    <StyledTableHead role="row" alignText={alignText} {...rest}>
      {children}
    </StyledTableHead>
  );
}

TableHead.defaultProps = {
  alignText: 'left',
};

TableHead.propTypes = {
  alignText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export function TableRow({ children, ...rest }) {
  return (
    <StyledTableRow role="row" {...rest}>
      {children}
    </StyledTableRow>
  );
}

TableRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

export function TableData({ alignText, children, ...rest }) {
  return (
    <StyledTableData alignText={alignText} {...rest}>
      {children}
    </StyledTableData>
  );
}

TableData.defaultProps = {
  alignText: 'left',
};

TableData.propTypes = {
  alignText: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

function Table({
  id, tbody, thead, dark, striped, children, ...rest
}) {
  const [isMaxDeviceMd, setIsMaxDeviceMd] = useState(false);

  function onResize() {
    setIsMaxDeviceMd(isMaxDevice('md'));
  }

  useEffect(() => {
    setIsMaxDeviceMd(isMaxDevice('md'));
    window.addEventListener('resize', onResize);

    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, [setIsMaxDeviceMd]);

  return (
    <StyledTable
      id={id}
      dark={dark}
      striped={striped}
      isMaxDevice={isMaxDeviceMd}
      {...rest}
    >
      <If test={children === null}>
        <>
          <If test={!isMaxDeviceMd}>
            <thead>
              <TableHeadRow role="row" dark={dark}>
                {thead}
              </TableHeadRow>
            </thead>
          </If>
          <tbody>{tbody}</tbody>
        </>
      </If>

      <If test={children}>{children}</If>
    </StyledTable>
  );
}

Table.Head = TableHead;
Table.HeadRow = TableHeadRow;
Table.Row = TableRow;
Table.Data = TableData;

Table.defaultProps = {
  id: null,
  dark: false,
  striped: false,
  children: null,
  thead: null,
  tbody: null,
};

Table.propTypes = {
  id: PropTypes.string,
  dark: PropTypes.bool,
  striped: PropTypes.bool,
  thead: PropTypes.arrayOf(PropTypes.any),
  tbody: PropTypes.arrayOf(PropTypes.any),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default Table;
