import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import handleVariants from '../helpers/handleVariants';

const Label = styled.label`
  margin: 0;
  transition: all 0.2s;
  color: ${({ variant, theme }) => handleVariants(variant, theme, theme.colors.gray300)};
  font-weight: 500;
  ${({ uppercase }) => uppercase
    && css`
      text-transform: uppercase;
    `}
  ${({ disabled, theme }) => disabled
    && css`
      cursor: not-allowed;
      color: ${theme.colors.gray100};
    `};
`;

Label.defaultProps = {
  uppercase: false,
  disabled: false,
};

Label.propTypes = {
  uppercase: PropTypes.bool,
  disabled: PropTypes.bool,
};

Label.displayName = 'Label';

export default Label;
