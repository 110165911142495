import React from 'react';
import styled from 'styled-components';
import { Spinner } from '../elements';

interface ILoader {
  isLoading: boolean;
  children: React.ReactNode;
}

const Container = styled.div`
  width: 100%;
  position: relative;
`;

const Content = styled.div<ILoader>`
  opacity: ${props => (props.isLoading ? '.5' : '1')};
`;

const SpinnerContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999;
`;

const Loader = ({ isLoading, children }: ILoader): JSX.Element => {
  return (
    <Container>
      {isLoading && (
        <SpinnerContainer data-testid="loader">
          {/* @ts-ignore */}
          <Spinner />
        </SpinnerContainer>
      )}
      <Content isLoading={isLoading}>{children}</Content>
    </Container>
  );
};

export default Loader;
