import React from 'react';
import PropTypes from 'prop-types';
import styled, { css, keyframes } from 'styled-components';
import FontAwesomeIcon from './FontAwesomeIcon';

function getColor({
  theme, checked, disabled, indeterminate,
}) {
  let color = theme.colors.gray400;
  if (disabled) {
    color = theme.colors.gray100;
  } else if (checked || indeterminate) {
    color = theme.colors.primary;
  }
  return color;
}

function getIcon(checked, indeterminate) {
  let icon = ['far', 'square'];

  if (checked) {
    icon = ['fas', 'check-square'];
  } else if (indeterminate) {
    icon = ['fas', 'minus-square'];
  }

  return icon;
}

const verticalAlignOptions = {
  center: 'center',
  top: 'flex-start',
  bottom: 'flex-end',
};

const animationChecked = keyframes`
  from {
    opacity: 0.2;
  }
  to {
    opacity: 1;
  }
`;

const animationUnchecked = keyframes`
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
`;

const Content = styled.div`
  margin-left: 13px;
  transition: color 0.2s linear;
`;

const Label = styled.label`
  display: flex;
  align-items: ${({ verticalAlign }) => verticalAlignOptions[verticalAlign]};
  color: ${props => getColor(props)};
  pointer-events: auto;
  i {
    font-size: 20px;
  }
  ${({ checked, indeterminate }) => {
    let animation = animationUnchecked;
    if (checked || indeterminate) {
      animation = animationChecked;
    }

    return css`
      i {
        animation: ${animation} 0.2s ease;
      }
    `;
  }}
  ${({ disabled }) => disabled
    && css`
      cursor: not-allowed;
    `}
  ${({ pointer, disabled }) => pointer
    && !disabled
    && css`
      cursor: pointer;
    `}
`;

function Checkbox({
  checked,
  indeterminate,
  disabled,
  children,
  onChange,
  ...rest
}) {
  const icon = getIcon(checked, indeterminate);

  function handleOnChange(e) {
    if (!disabled && onChange) {
      onChange(e);
    }
  }

  return (
    <Label
      role="checkbox"
      aria-checked={checked}
      checked={checked}
      indeterminate={indeterminate}
      disabled={disabled}
      onClick={handleOnChange}
      pointer={!!onChange}
      {...rest}
    >
      <FontAwesomeIcon icon={icon} />
      {children && <Content>{children}</Content>}
    </Label>
  );
}

Checkbox.displayName = 'Checkbox';

Checkbox.propTypes = {
  checked: PropTypes.bool,
  indeterminate: PropTypes.bool,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  onChange: PropTypes.func,
  verticalAlign: PropTypes.oneOf(['center', 'top', 'bottom']),
};

Checkbox.defaultProps = {
  checked: false,
  indeterminate: false,
  disabled: false,
  children: null,
  onChange: null,
  verticalAlign: 'center',
};

export default Checkbox;
