import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Select from '../elements/Select';
import Option from '../elements/Option';
import Label from '../elements/Label';
import MessageError from '../elements/MessageError';

const DropdownContainer = styled.div`
  position: relative;
  padding-top: 1rem;
  label {
    position: absolute;
    left: 0.5rem;
    top: 1.5rem;
    ${({ hasItem }) => hasItem
      && css`
        top: 0;
        font-size: 0.8rem;
        font-weight: 600;
      `}
  }
`;

function Dropdown({
  id,
  label,
  name,
  placeholder,
  messageError,
  disabled,
  uppercase,
  children,
  value,
  disableResetValue,
  ...rest
}) {
  const [hasItem, setHasItem] = useState(false);

  useEffect(() => {
    const status = value === 0 ? true : !!value;
    setHasItem(status);
  }, [value]);

  return (
    <DropdownContainer hasItem={hasItem}>
      <Select
        name={name}
        id={id || name}
        placeholder=""
        disabled={disabled}
        invalid={!!messageError}
        value={value}
        disableResetValue={disableResetValue}
        {...rest}
      >
        {children}
      </Select>
      <Label htmlFor={id || name} disabled={disabled} uppercase={uppercase}>
        {label}
      </Label>
      {messageError && (
        <MessageError id={`error-${name}`}>{messageError}</MessageError>
      )}
    </DropdownContainer>
  );
}

Dropdown.displayName = 'Dropdown';

Dropdown.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  messageError: PropTypes.string,
  disabled: PropTypes.bool,
  uppercase: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.arrayOf(PropTypes.element),
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disableResetValue: PropTypes.bool,
};

Dropdown.defaultProps = {
  id: null,
  label: null,
  name: null,
  placeholder: null,
  messageError: '',
  disabled: false,
  uppercase: false,
  children: null,
  value: null,
  disableResetValue: false,
};

Dropdown.Item = Option;

export default Dropdown;
